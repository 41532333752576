<template>

    <div class="section helpful-tips">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="section-head">
                        <h3 class="section-head__title">Wusstest du schon...?</h3>
                        <h4 class="section-head__info">Hilfreiche Tipps aus unserem Ratgeber:</h4>
                    </div>
                </div>
            </div>

            <ratgeber-row v-for="(rat,index) in ratgeber" :key="index" :category="rat"/>

        </div>
    </div>

</template>

<script>

  export default {
    name: "ratgeberSection",
    components: {
        RatgeberRow: () => import('./ratgeberRow.vue'),
    },
    data() {
        return {
            ratgeber: [
            {
                color: 'meganta',
                imageIcon: '/assets/icons/Integration.svg',
                name: 'Neu in Wolfsburg',
                slug: 'NeuinWolfsburg',
                desc: 'Infos für Neubürger*innen und Geflüchtete.',
            },
            {
                color: 'purple',
                imageIcon: '/assets/icons/alter.svg',
                name: 'Senior*innen',
                slug: 'Senior*innen',
                desc: 'Tipps & Tricks für ein erfülltes Leben im Alter.',
            },
            {
                color: 'jade',
                imageIcon: '/assets/icons/Familie.svg',
                name: 'Eltern',
                slug: 'Eltern',
                desc: 'Tipps & Ratschläge für Eltern und Erziehungsberechtige.',
            },
            {
                color: 'blue',
                imageIcon: '/assets/icons/Erwachsene.svg',
                name: 'Erwachsene',
                slug: 'Erwachsene',
                desc: 'Praktische Infos für den Alltag im Erwachsenenleben.',
            },
            {
                color: 'orange',
                imageIcon: '/assets/icons/KinderJugendliche.svg',
                name: 'Kinder & Jugendliche',
                slug: 'Kinder&Jugendliche',
                desc: 'Infos und Hilfestellungen für Kinder & Jugendliche.',
            }],
        }
    },
}
</script>

